import axios from "axios";
import { CLD_ENV, NODE_ENV } from "../../constants/env";
import { useURLs } from "../../constants/api.urls";
import nuxtStorage from "nuxt-storage";
import { useCldURLs } from "~/constants/apiv2.urls";

export const getProducts = async ({
  sortOrder,
  filterInput,
  range,
  startRowIndex,
  selectedFilters,
  selectedLanguage,
  // sessionData,
}) => {
  try {
    const url = useURLs[NODE_ENV].PRODUCT_SEARCH_URL;
    const payload = JSON.stringify({
      prefix: filterInput,
      range: range,
      startRowIndex: startRowIndex,
      filters: selectedFilters,
      selectedLanguage,
      sortOrder: sortOrder,
      // sessionData,
    });
    // const { data } = await axios.post(
    //   url,
    //   {
    //     prefix: filterInput,
    //     range: range,
    //     startRowIndex: startRowIndex,
    //     filters: selectedFilters,
    //     selectedLanguage,
    //     sortOrder: sortOrder,
    //     // sessionData,
    //   },
    //   {
    //     headers: {
    //       client_id: useURLs[NODE_ENV].CLIENT_ID,
    //       client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer  ${localStorage.getItem("access_token")}`,
    //     },
    //   }
    // );
    const token = nuxtStorage.localStorage.getData("token");
    const { data } = await useFetch(url, {
      key: `${payload}`,
      method: "POST",
      body: payload,
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
        ...{ ...(token && { ...{ Authorization: `Bearer  ${token}` } }) },
      },
      retry: 1,
    });
    const productsCount = data.value["count"];
    const pageCount = Math.ceil(productsCount / range);
    const products = data.value["productsN"];

    const attributeFilters = data.value["aggregations"];

    return {
      success: true,
      products,
      attributeFilters: attributeFilters,
      productsCount,
      pageCount,
    };
  } catch (error) {
    console.error("error getting products", error);
    return { success: false };
  }
};

// eslint-disable-next-line no-unused-vars
export const getCategoryFilters = async ({ prefix, selectedLanguage }) => {
  // const myHeaders = new Headers();
  // myHeaders.append("client_id", useURLs[NODE_ENV].CLIENT_ID);
  // myHeaders.append("client_secret", useURLs[NODE_ENV].CLIENT_SECERET);

  // const requestOptions = {
  //   method: "GET",
  //   headers: myHeaders,
  //   // body: JSON.stringify({ prefix, filters }),
  // };
  // const res = await fetch(`${url}?prefix=${prefix}`, requestOptions);
  // const data = await res.json();
  const url = useURLs[NODE_ENV].CATEGORY_FILTERS_URL;

  const token = nuxtStorage.localStorage.getData("token");
  const { data } = await useFetch(
    `${url}?prefix=${prefix}&selectedLanguage=${selectedLanguage}`,
    {
      key: prefix,
      method: "GET",
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
        ...{ ...(token && { ...{ Authorization: `Bearer  ${token}` } }) },
      },
      retry: 1,
    }
  );
  return data;
};
export const getSuggestions = async ({ keyword, chainId }) => {
  try {
    if (!keyword) {
      return [];
    }

    const url = useURLs[NODE_ENV].SUGGESTION_URL;
    const { data } = await axios.get(`${url}${keyword}&chainId=${chainId}`, {
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
        Authorization: `Bearer  ${localStorage.getItem("access_token")}`,
      },
    });

    return data;
  } catch (error) {
    console.error("error getting suggestions", error);
    return [];
  }
};

export const findProduct = async ({ id, selectedLanguage }) => {
  if (!id) {
    return;
  }

  const url = `${useURLs[NODE_ENV].PRODUCT_FIND_URL}?id=${id}`;
  const payload = JSON.stringify({
    ids: id,
    selectedLanguage,
  });
  const token = nuxtStorage.localStorage.getData("token");
  const { data } = await useFetch(url, {
    key: `${payload}`,
    method: "POST",
    body: payload,
    headers: {
      client_id: useURLs[NODE_ENV].CLIENT_ID,
      client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
      "Content-Type": "application/json",
      ...{ ...(token && { ...{ Authorization: `Bearer  ${token}` } }) },
    },
    retry: 1,
  });

  if (data.value && data.value["product"]) {
    return data.value["product"];
  } else {
    return null;
  }
};

export const findManyProductsByIds = async ({ ids = [], selectedLanguage }) => {
  if (!ids.length) {
    return;
  }

  const url = `${useURLs[NODE_ENV].PRODUCT_FIND_MANY_URL}?ids=${ids}`;
  const payload = JSON.stringify({
    ids: ids,
    selectedLanguage,
  });
  const token = nuxtStorage.localStorage.getData("token");
  const { data } = await useFetch(url, {
    key: `${payload}`,
    method: "POST",
    body: payload,
    headers: {
      client_id: useURLs[NODE_ENV].CLIENT_ID,
      client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
      "Content-Type": "application/json",
      ...{ ...(token && { ...{ Authorization: `Bearer  ${token}` } }) },
    },
    retry: 1,
  });
  return data;
};

export const getProductPreSalePercentage = async (id) => {
  const url = `${useCldURLs[CLD_ENV].product.get_pre_sale_percentage}?stockId=${id}`;
  try {
    const token = nuxtStorage.localStorage.getData("token");
    if (token) {
      const { data } = await useFetch(url, {
        key: id,
        method: "GET",
        headers: {
          Authorization: `Bearer  ${token}`,
          //...{ ...(token && { ...{ Authorization: `Bearer  ${token}` } }) },
        },
        onResponseError({ response }) {
          if (response.status === 403) {
            useUnAutorize();
          }
        },

        retry: 1,
      });
      return data;
    }
    return -1;
  } catch (error) {
    return -1;
  }
};

export const getReleaseProducts = async ({
  releaseFilters,
  startRowIndex,
  range,
  selectedLanguage,
  sortOrder,
}) => {
  try {
    const url = useURLs[NODE_ENV].PRODUCT_RELEASE_SEARCH_URL;
    const { data } = await axios.post(
      url,
      {
        releaseFilters,
        startRowIndex,
        range,
        selectedLanguage,
        sortOrder,
      },
      {
        headers: {
          client_id: useURLs[NODE_ENV].CLIENT_ID,
          client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
          "Content-Type": "application/json",
          Authorization: `Bearer  ${localStorage.getItem("access_token")}`,
        },
      }
    );
    const productsCount = data["count"];
    const pageCount = Math.ceil(productsCount / range);
    const products = data["productsN"];
    const attributeFilters = data["aggregations"];

    return {
      success: true,
      products,
      attributeFilters: attributeFilters,
      productsCount,
      pageCount,
    };
  } catch (error) {
    console.error("error getting products", error);
    return { success: false };
  }
};

export const getAllFutureReleaseProducts = async ({
  releaseFilters,
  startRowIndex,
  range,
  selectedLanguage,
  sortOrder,
}) => {
  try {
    const url = useURLs[NODE_ENV].PRODUCT_ALL_FUTURE_RELEASE_SEARCH_URL;
    const { data } = await axios.post(
      url,
      {
        releaseFilters,
        startRowIndex,
        range,
        selectedLanguage,
        sortOrder,
      },
      {
        headers: {
          client_id: useURLs[NODE_ENV].CLIENT_ID,
          client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
          "Content-Type": "application/json",
          Authorization: `Bearer  ${localStorage.getItem("access_token")}`,
        },
      }
    );
    const productsCount = data["count"];
    const pageCount = Math.ceil(productsCount / range);
    const products = data["products"];
    const attributeFilters = data["aggregations"];

    return {
      success: true,
      products,
      attributeFilters: attributeFilters,
      productsCount,
      pageCount,
    };
  } catch (error) {
    console.error("error getting products", error);
    return { success: false };
  }
};

export const getProductsStocksByIds = async ({ ids }) => {
  const url = useURLs[NODE_ENV].PRODUCT_STOCK_BY_IDS_URL;
  const { data } = await axios.post(
    url,
    {
      ids,
    },
    {
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",
        Authorization: `Bearer  ${localStorage.getItem("access_token")}`,
      },
    }
  );
  console.log("stock data", data);
  return data;
};

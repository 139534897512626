const CLD_PATH_LOCAL_DEV = "https://localhost:7083/";
const CLD_PATH_PROD = "https://b12api.cld.eu/";
const CLD_PATH_STAGING: string = "https://dev.api.cld.eu/"; // "http://192.168.81.13:7083/"; 

const CLD_FE_PATH_LOCAL_DEV = "https://localhost:3001/";
const CLD_FE_PATH_PROD = "https://cld.eu/";
const CLD_FE_PATH_STAGING = "https://dev.api.cld.eu/"; //"http://192.168.81.13:7083/"



type UseCldURLsType = typeof useCldURLs;

// Define environment constants using the mapped `envType`


const useCldURLs = Object.freeze({
  production: {
    CLD_URL: CLD_PATH_PROD,
    CLD_FE_URL: CLD_FE_PATH_PROD,
    auth: {
      login: CLD_PATH_PROD + "auth/login",
      logout: CLD_PATH_PROD + "auth/logout",
      pre_register: CLD_PATH_PROD + "auth/pre-register",
      post_register: CLD_PATH_PROD + "auth/post-register",
      forgot_password: CLD_PATH_PROD + "auth/forgot-password",
      reset_password: CLD_PATH_PROD + "auth/reset-password",
    },
    user: {
      email: CLD_PATH_PROD + "user/emails",
      profile: CLD_PATH_PROD + "user/profile",
    },
    utility: {
      check_vat: CLD_PATH_PROD + "utility/check-vat-exist-and-isvalid",
      email_exists: `${CLD_PATH_PROD}utility/check-email-exist`,
      download_pdf: `${CLD_PATH_PROD}utility/download-pdf`,
      countries: `${CLD_PATH_PROD}utility/countries`,
    },
    cart: {
      get: CLD_PATH_PROD + "cart/get",
      add: CLD_PATH_PROD + "cart/add",
      update: CLD_PATH_PROD + "cart/update",
      remove: CLD_PATH_PROD + "cart/remove",
      reset: CLD_PATH_PROD + "cart/reset",
    },
    order: {
      place: CLD_PATH_PROD + "order/place",
      is_po_req: CLD_PATH_PROD + "order/is-purchase-order-required",
      re_open: CLD_PATH_PROD + "order/re-open",
    },
    product: {
      get_back_orders: CLD_PATH_PROD + "product/get-back-orders",
      get_pre_sale_percentage: CLD_PATH_PROD + "product/get-pre-sale-percentage",
    },
    account: {
      history: `${CLD_PATH_PROD}account/history`,
      reopen: `${CLD_PATH_PROD}account/reopen`,
      download_history: `${CLD_PATH_PROD}account/download-history`,
      search_order_history: `${CLD_PATH_PROD}account/search-order-history`,
      search_orders: `${CLD_PATH_PROD}account/search-orders`,
      search_supplier: `${CLD_PATH_PROD}account/search-supplier-orders`,
      back_orders: `${CLD_PATH_PROD}account/back-orders`,
      deliverable_orders: `${CLD_PATH_PROD}account/deliverable-orders`,
      deliverable_orders_total: `${CLD_PATH_PROD}account/deliverable-orders-total`,
      undeliverable_orders: `${CLD_PATH_PROD}account/undeliverable-orders`,
      open_docs: `${CLD_PATH_PROD}account/open-docs`,
      accounting_situation: `${CLD_PATH_PROD}account/accounting-situation`,
      shipment_link: `${CLD_PATH_PROD}account/shipment-link`,

    },
    price_down: {
      get: `${CLD_PATH_PROD}pricedown/get`,
      find: `${CLD_PATH_PROD}pricedown/find`,
      request_price_down: `${CLD_PATH_PROD}pricedown/request-price-down`,
    }
  },
  staging: {
    CLD_URL: CLD_PATH_STAGING,
    CLD_FE_URL: CLD_FE_PATH_STAGING,
    auth: {
      login: CLD_PATH_STAGING + "auth/login",
      logout: CLD_PATH_STAGING + "auth/logout",
      pre_register: CLD_PATH_STAGING + "auth/pre-register",
      post_register: CLD_PATH_STAGING + "auth/post-register",
      forgot_password: CLD_PATH_STAGING + "auth/forgot-password",
      reset_password: CLD_PATH_STAGING + "auth/reset-password",
    },
    user: {
      email: CLD_PATH_STAGING + "user/emails",
      profile: CLD_PATH_STAGING + "user/profile",
    },
    utility: {
      check_vat: CLD_PATH_STAGING + "utility/check-vat-exist-and-isvalid",
      email_exists: `${CLD_PATH_STAGING}utility/check-email-exist`,
      download_pdf: `${CLD_PATH_STAGING}utility/download-pdf`,
      countries: `${CLD_PATH_STAGING}utility/countries`,
    },
    cart: {
      get: CLD_PATH_STAGING + "cart/get",
      add: CLD_PATH_STAGING + "cart/add",
      update: CLD_PATH_STAGING + "cart/update",
      remove: CLD_PATH_STAGING + "cart/remove",
      reset: CLD_PATH_STAGING + "cart/reset",
    },
    order: {
      place: CLD_PATH_STAGING + "order/place",
      is_po_req: CLD_PATH_STAGING + "order/is-purchase-order-required",
      re_open: CLD_PATH_STAGING + "order/re-open",
    },
    product: {
      get_back_orders: CLD_PATH_STAGING + "product/get-back-orders",
      get_pre_sale_percentage: CLD_PATH_STAGING + "product/get-pre-sale-percentage",
    },
    account: {
      history: `${CLD_PATH_STAGING}account/history`,
      reopen: `${CLD_PATH_STAGING}account/reopen`,
      download_history: `${CLD_PATH_STAGING}account/download-history`,
      search_order_history: `${CLD_PATH_STAGING}account/search-order-history`,
      search_orders: `${CLD_PATH_STAGING}account/search-orders`,
      search_supplier: `${CLD_PATH_STAGING}account/search-supplier-orders`,
      back_orders: `${CLD_PATH_STAGING}account/back-orders`,
      deliverable_orders: `${CLD_PATH_STAGING}account/deliverable-orders`,
      deliverable_orders_total: `${CLD_PATH_STAGING}account/deliverable-orders-total`,
      undeliverable_orders: `${CLD_PATH_STAGING}account/undeliverable-orders`,
      open_docs: `${CLD_PATH_STAGING}account/open-docs`,
      accounting_situation: `${CLD_PATH_STAGING}account/accounting-situation`,
      shipment_link: `${CLD_PATH_STAGING}account/shipment-link`,

    },
    price_down: {
      get: `${CLD_PATH_STAGING}pricedown/get`,
      find: `${CLD_PATH_STAGING}pricedown/find`,
      request_price_down: `${CLD_PATH_STAGING}pricedown/request-price-down`,
    }
  },
  development: {
    CLD_URL: CLD_PATH_LOCAL_DEV,
    CLD_FE_PATH_LOCAL_DEV: CLD_FE_PATH_LOCAL_DEV,
    auth: {
      login: CLD_PATH_LOCAL_DEV + "auth/login",
      logout: CLD_PATH_LOCAL_DEV + "auth/logout",
      pre_register: CLD_PATH_LOCAL_DEV + "auth/pre-register",
      post_register: CLD_PATH_LOCAL_DEV + "auth/post-register",
      forgot_password: CLD_PATH_LOCAL_DEV + "auth/forgot-password",
      reset_password: CLD_PATH_LOCAL_DEV + "auth/reset-password",
    },
    user: {
      email: CLD_PATH_LOCAL_DEV + "user/emails",
      profile: CLD_PATH_LOCAL_DEV + "user/profile",
    },
    utility: {
      check_vat: CLD_PATH_LOCAL_DEV + "utility/check-vat-exist-and-isvalid",
      email_exists: `${CLD_PATH_LOCAL_DEV}utility/check-email-exist`,
      download_pdf: `${CLD_PATH_LOCAL_DEV}utility/download-pdf`,
      countries: `${CLD_PATH_LOCAL_DEV}utility/countries`,
    },
    cart: {
      get: CLD_PATH_LOCAL_DEV + "cart/get",
      add: CLD_PATH_LOCAL_DEV + "cart/add",
      update: CLD_PATH_LOCAL_DEV + "cart/update",
      remove: CLD_PATH_LOCAL_DEV + "cart/remove",
      reset: CLD_PATH_LOCAL_DEV + "cart/reset",
    },
    order: {
      place: CLD_PATH_LOCAL_DEV + "order/place",
      is_po_req: CLD_PATH_LOCAL_DEV + "order/is-purchase-order-required",
      re_open: CLD_PATH_LOCAL_DEV + "order/re-open",
    },
    product: {
      get_back_orders: CLD_PATH_LOCAL_DEV + "product/get-back-orders",
      get_pre_sale_percentage: CLD_PATH_LOCAL_DEV + "product/get-pre-sale-percentage",
    },
    account: {
      history: `${CLD_PATH_LOCAL_DEV}account/history`,
      reopen: `${CLD_PATH_LOCAL_DEV}account/reopen`,
      download_history: `${CLD_PATH_LOCAL_DEV}account/download-history`,
      search_order_history: `${CLD_PATH_LOCAL_DEV}account/search-order-history`,
      search_orders: `${CLD_PATH_LOCAL_DEV}account/search-orders`,
      search_supplier: `${CLD_PATH_LOCAL_DEV}account/search-supplier-orders`,
      back_orders: `${CLD_PATH_LOCAL_DEV}account/back-orders`,
      deliverable_orders: `${CLD_PATH_LOCAL_DEV}account/deliverable-orders`,
      deliverable_orders_total: `${CLD_PATH_LOCAL_DEV}account/deliverable-orders-total`,
      undeliverable_orders: `${CLD_PATH_LOCAL_DEV}account/undeliverable-orders`,
      open_docs: `${CLD_PATH_LOCAL_DEV}account/open-docs`,
      accounting_situation: `${CLD_PATH_LOCAL_DEV}account/accounting-situation`,
      shipment_link: `${CLD_PATH_LOCAL_DEV}account/shipment-link`,
    },
    price_down: {
      get: `${CLD_PATH_LOCAL_DEV}pricedown/get`,
      find: `${CLD_PATH_LOCAL_DEV}pricedown/find`,
      request_price_down: `${CLD_PATH_LOCAL_DEV}pricedown/request-price-down`,
    }
  },
} as const);


export { useCldURLs };
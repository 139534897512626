import { NODE_ENV } from "../../constants/env";
import { useURLs } from "../../constants/api.urls";

export const getParentCategoriesHttp = async () => {
  const url = useURLs[NODE_ENV].CATEGORY_PARENT;

  const { data } = await useFetch(url, {
    method: "GET",
    headers: {
      client_id: useURLs[NODE_ENV].CLIENT_ID,
      client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
      "Content-Type": "application/json",
    },
    retry: 1,
  });
  return data;
};

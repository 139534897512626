import { useUserStore } from "~/store/UserStore";
import nuxtStorage from "nuxt-storage";
// For using in pini stores and http call funcs
export const useUnAutorize = () => {
 
  const userStore = useUserStore();
  const route = useRoute();
  const router = useRouter();
  const localePath = useLocalePath();
  const { t, locale } = useI18n();
  nuxtStorage.localStorage.removeItem("token");
  useNuxtApp().$showToast({ msg: t("session_expired"), type: "error" });
  userStore.setIsAuthenticated();
  router.push(
    localePath(`/account/login?redirectTo=${route.fullPath}`)
  );
};
